export const zhTW = {
  translation: {
    "title/Crossnote": "交叉筆記",
    "search/notes": "搜尋筆記",
    "search/notebooks": "搜尋筆記本",
    "search/attachments": "搜尋附件",

    // Account
    "account/Anonymous": "匿名",
    "account/anonymous": "匿名",

    // General
    "general/go-back": "回退",
    "general/sending": "發送中",
    "general/send": "發送",
    "general/cancel": "取消",
    "general/add": "添加",
    "general/adding": "正在添加",
    "general/backed-up-at": "備份於",
    "general/created-at": "創建於",
    "general/modified-at": "修改於",
    "general/interacted-at": "互動於",
    "general/date-created": "創建時間",
    "general/date-modified": "修改時間",
    "general/date-interacted": "互動時間",
    "general/title": "標題",
    "general/saved-at": "保存於",
    "general/loading": "讀取中 ...",
    "general/loading-notifications": "正在讀取消息 ...",
    "general/failed-to-set": "設置失敗",
    "general/update": "更新",
    "general/updated": "已更新",
    "general/updating": "正在更新",
    "general/close": "關閉",
    "general/Note": "筆記",
    "general/notes": "筆記",
    "general/Settings": "設置",
    "general/Collaborators": "協作者",
    "general/collaborators": "協作者",
    "general/Backups": "備份",
    "general/backups": "備份",
    "general/Discussion": "討論",
    "general/discussion": "討論",
    "general/Notifications": "消息",
    "general/Username": "用戶名",
    "general/Email": "郵箱",
    "general/Password": "密碼",
    "general/confirm-password": "再次確認密碼",
    "general/Continue": "下一步",
    "general/enter-username": "輸入用戶名",
    "general/echomd": "編輯",
    "general/tags": "標籤",
    "general/add-a-tag": "添加一個標籤",
    "general/edit-note-alias": "編輯筆記別名",
    "general/no-aliases": "沒有別名",
    "general/add-an-alias": "添加壹個別名",
    "general/private": "私有",
    "general/public": "公開可見",
    "general/friends-only": "僅好友可見",
    "general/anonymous": "匿名",
    "general/onymous": "公開署名",
    "general/Tag": "標籤",
    "general/no-tags": "沒有標籤",
    "general/Directory": "目錄",
    "general/Untitled": "無標題",
    "general/untitled": "無標題",
    "general/Unknown": "未知",
    "general/unknown": "未知",
    "general/Agree": "同意",
    "general/Disagree": "不同意",
    "general/Print": "打印",
    "general/Share": "分享",
    "Editor": "編輯者",
    "EDITOR": "編輯者",
    "Viewer": "閱讀者",
    "VIEWER": "閱讀者",
    "Admin": "管理員",
    "ADMIN": "管理員",
    "ARCHIVED": "歸檔的筆記",
    "ATTACHED NOTES": "附加的筆記",
    "DELETED": "刪除的筆記",
    "PINNED": "置頂",
    "OTHERS": "其它",
    "PUBLIC NOTES": "公開的筆記",
    "FOLLOWINGS' NOTES": "關注人的筆記",
    "SHARED WITH ME": "分享給我的",
    "BOOKMARKS": "收藏",
    "MY TAGS": "我的標籤",
    "general/enter-fullscreen-mode": "進入全屏模式",
    "general/enter-presentation-mode": "進入幻燈片模式",
    "general/math-preview": "數學預覽",
    "general/this-note-is-empty": "這個筆記沒有內容",
    "general/this-notebook-is-empty": "這個筆記本沒有內容",
    "general/nothing-here": "這裡什麼都沒有",
    "general/no-notes-found": "沒有找到其它的筆記",
    "general/no-more-attachments-found": "没有找到其它的附件",
    "general/no-more-notebooks-found": "沒有找到其它的筆記本",
    "general/no-notifications-found": "沒有找到其它的消息",
    "general/clear-all": "清空所有",
    "general/Save": "保存",
    "general/Delete": "刪除",
    "general/Follow": "關注",
    "general/Following": "正在關注",
    "general/Unfollow": "取消關注",
    "general/my-notes": "我的筆記",
    "general/public-notes": "公開的筆記",
    "general/followings-notes": "關注人的筆記",
    "general/shared-with-me": "分享給我的",
    "general/Bookmarks": "收藏",
    "general/Archive": "歸檔",
    "general/Trash": "廢紙簍",
    "general/new-note": "新的筆記",
    "general/new-attachment": "新的附件",
    "general/profile-updated": "用戶信息已更新",
    "general/ID": "ID",
    "general/Audio": "音頻",
    "general/Video": "視頻",
    "general/source-url": "源鏈接",
    "general/sign-in": "登錄",
    "general/sign-up": "註冊",
    "general/Link": "鏈接",
    "general/Upload": "上傳",
    "general/Uploading": "正在上傳",
    "general/clicks": "點擊",
    "general/Processing": "正在處理...",
    "general/please-wait": "請稍候...",
    "general/please-sign-in-first": "請先登錄",
    "general/Languages": "語言",
    "general/track": "跟蹤",
    "general/Publish": "發布",
    "general/Unpublish": "取消發佈",
    "general/Open": "開啟",
    "general/Download": "下載",
    "general/Configure": "設定",
    "general/Update": "更新",
    "general/empty": "空的",
    "general/Explore": "探索",
    "general/Notebooks": "筆記本",
    "general/add-a-notebook": "添加一個筆記本",
    "general/add-a-note": "添加一个筆記",
    "general/add-an-attachment": "添加一个附件",
    "general/configure-the-notebook": "設置筆記本",
    "general/publish-your-notebook": "發佈你的筆記本",
    "general/notebook-name": "筆記本名稱",
    "general/git-repository": "git 倉庫",
    "general/clone-a-git-repository": "克隆壹個 Git 倉庫",
    "general/optional": "可選",
    "general/url": "Url",
    "general/branch": "分支",
    "general/remember-username-and-password": "記住用戶名和密碼",
    "general/stored-locally": "儲存於本地",
    "general/cors-proxy": "CORS 代理",
    "general/why-cors-proxy": "為什麼需要 CORS 代理？",
    "general/today": "今天",
    "general/todo": "待辦事項",
    "general/graph-view": "關聯圖",
    "general/wiki": "維琪",
    "general/tagged": "標籤",
    "general/untagged": "未標籤",
    "general/encrypt": "加密",
    "general/encrypted": "已加密",
    "general/decrypt": "解密",
    "general/conflicted": "衝突",
    "general/attachments": "附件",
    "general/Pin": "置頂",
    "general/Encryption": "加密",
    "general/Fullscreen": "全屏",
    "general/change-file-path": "更改檔路徑",
    "general/create-a-copy": "建立複本",
    "general/restore-checkout": "恢復 (git checkout)",
    "general/download-pull": "下載 (git pull)",
    "general/upload-push": "上傳 (git pull & git push)",
    "general/disable-the-encryption-on-this-note": "解除這個筆記上的加密",
    "general/encrypt-this-note-with-password": "通過密碼加密這個筆記",
    "general/disable-encryption": "解除加密",
    "general/decrypt-this-note": "解密這個筆記",
    "general/upload-the-profile": "上傳賬戶信息",
    "general/commit-message": "提交說明",
    "general/upload-notebook": "上傳筆記本",
    "general/Comments": "評論",
    "general/Source": "源",
    "general/Description": "描述",
    "general/Edit": "編輯",
    "general/Rename": "重命名",
    "general/rename-directory": "重命名目錄",
    "general/delete-directory": "删除目錄",
    "general/rename-tag": "重命名標籤",
    "general/delete-tag": "删除標籤",
    "general/notebook-updates-found": "發現筆記本更新",
    "general/update-the-notebook": "更新這個筆記本",
    "general/check-notebook-updates-periodically": "週期性地檢查筆記本更新",
    "general/minutes": "分鐘",
    "general/hard-reset": "硬重設",
    "general/Default": "預設",
    "general/Vim": "Vim",
    "general/Emacs": "Emacs",
    "general/Desc": "遞減",
    "general/Asc": "遞增",
    "general/pin-the-note": "置頂這個筆記",
    "general/unpin-the-note": "取消置頂這個筆記",
    "general/add-to-quick-access": "添加到快速訪問",
    "general/remove-from-quick-access": "從快速訪問中移除",
    "general/open-folder": "打開文件夾",
    "general/open-a-local-folder": "打開壹個本地文件夾",
    "general/your-browser-doesnt-support-to-open-local-folder":
      "妳的瀏覽器不支持打開本地文件夾",
    "general/refresh": "刷新",
    "general/welcome-back-to-crossnote": "歡迎回到交叉筆記",
    "general/continue": "繼續",
    "general/reference(s)": "引用",
    "general/References": "引用",

    // Error
    "error/authentication-failed": "認証失敗",
    "error/failed-to-upload-notebook": "上傳筆記本失敗",
    "error/failed-to-download-notebook": "下載筆記本失敗",
    "error/notebook-already-exists": "筆記本已經存在",
    "error/invalid-git-url-prefix": "無效的 git URL 前綴",
    "error/please-resolve-conflicts": "請先解決沖突",
    "error/failed-to-disable-encryption": "解除加密失敗",
    "error/decryption-failed": "解密失敗",
    "error/failed-to-change-file-path": "更改文件路徑失敗",
    "error/failed-to-create-widget": "創建掛件失敗",
    "error/failed-to-load-widget": "讀取掛件失敗",
    "error/failed-to-update-the-notebook": "更新筆記本失敗",
    "error/failed-to-unpublish-the-notebook": "取消發佈筆記本失敗",
    "error/failed-to-publish-notebook": "發佈筆記本失敗",

    // Success
    "success/notebook-uploaded": "筆記本已上傳",
    "success/notebook-downloaded": "筆記本已下載",

    // Info
    "info/downloading-notebook": "正在下載筆記本",

    // Note
    "note/unarchive-note": "取消歸檔",
    "note/archive-note": "歸檔",
    "note/delete-forever": "永久刪除",
    "note/delete-note": "刪除",
    "note/restore-note": "恢復",
    "note/report-note": "舉報",
    "note/open-in-crossnote-website": "在交叉筆記網站中打開",

    // NoteTags
    "note-tags/send-suggestion-failure": "發送建議失敗",
    "note-tags/suggestion-sent": "建議已發送",
    "note-tags/suggest-a-tag-to-this-note": "給這個筆記建議一個標籤",

    // UserTags
    "user-tags/follow-a-tag": "關注一個標籤",

    // Notifications
    "notifications/refresh": "刷新來查看新的消息",
    "notifications/new-version-available": "發現了新版本的交叉筆記!",

    // Settings
    "settings/about-this-project": "關於這個項目",
    "settings/issues-and-feature-requests": "發現左問題或者有功能請求?",
    "settings/uploading-cover-image": "上傳封面圖片",
    "settings/upload-image-failure": "上傳圖片失敗",
    "settings/Cover": "封面圖片",
    "settings/enter-cover-image-url": "輸入封面圖片鏈接",
    "settings/upload-image": "上傳圖片",
    "settings/Avatar": "頭像",
    "settings/enter-avatar-image-url": "輸入頭像圖片鏈接",
    "settings/Name": "名字",
    "settings/enter-name": "輸入你的名字（這個不是你的用戶名）",
    "settings/editor-cursor-color": "編輯器光標顏色",
    "settings/default-editor-mode": "默认编辑器模式",
    "settings/hello": "你好，",
    "settings/world": "世界！",
    "settings/linked-with-github-account": "已關聯 GitHub 帳號",
    "settings/link-with-github-account": "關聯到 GitHub 帳號",
    "settings/log-out": "登出",
    "settings/author-name": "作者姓名",
    "settings/author-email": "作者郵箱",
    "settings/theme": "主題",
    "settings/created-cloud-widgets-count": "建立的雲掛件數量",
    "settings/key-map": "鍵盤對應",

    // Preview
    "preview/attach-a-note": "附加一個筆記",
    "preview/crossnote-untitled": "交叉筆記 - 無標題",
    "preview/edit-this-note": "編輯這個筆記",

    // ProfileCard
    "profile-card/followings": "關注",
    "profile-card/followers": "粉絲",
    "profile-card/Followings": "關注",
    "profile-card/Followers": "粉絲",
    "profile-card/notes": "筆記",
    "profile-card/follow-user-failure": "無法關注這個用戶",
    "profile-card/followed-user": "關注了這個用戶",
    "profile-card/unfollow-user-failure": "無法取消關注這個用戶",
    "profile-card/unfollowed-user": "取消關注了這個用戶",

    // Interaction panel
    "interaction-panel/show-attached-notes": "顯示附加的筆記",
    "interaction-panel/add-comment": "添加評論",
    "interaction-panel/add-reaction": "添加表情",

    // Editor
    "editor/loading-your-note": "正在讀取你的筆記...",
    "editor/sync-failure": "同步失敗... 請重啟網頁",
    "editor/saving": "正在保存...",
    "editor/save-failure": "保存失敗",
    "editor/saved": "已保存",
    "editor/update-note-failure": "更新筆記失敗",
    "editor/placeholder": "#  你好 😀 輸入 '/' 打開命令",
    "editor/ln": "行",
    "editor/col": "列",
    "editor/split-horizontally": "水平拆分",
    "editor/split-vertically": "垂直拆分",

    // * NoteControl
    "editor/note-control/allow-readers-to-suggest-note-tags":
      "允許讀者向你建議筆記的標籤",
    "editor/note-control/allow-readers-to-attach-notes-under-this-note":
      "允許讀者在這個筆記下附加筆記",
    "editor/note-control/inherit-parent-note-collaborators-list":
      "繼承上一級筆記的協作者列表",
    "editor/note-control/note-visibility-hint":
      "所有的協作者也將能夠看到這個筆記",
    "editor/note-control/allow-readers-to-attach-notes-unchecked":
      "適用於你以及所有可編輯或可管理這個筆記的協作者",
    "editor/note-control/allow-readers-to-attach-notes-checked":
      "適用於所有可以看到這個筆記的人",
    "editor/note-control/enter-parent-note-id": "輸入上一級筆記的 ID",
    "editor/note-control/attach-note-failure": "附加筆記失敗",
    "editor/note-control/unattach-note-failure": "取消附加筆記失敗",
    "editor/collaborator-hint": " - 他們將會看到這個筆記",
    "editor/shareable-link-hint":
      "所有擁有這個鏈接的人無需登錄即可查看/修改這個筆記",

    // * BackupsControl
    "editor/backups-control/create-backup": "創建備份",
    "editor/backups-control/delete-this-backup": "刪除這個備份",
    "editor/backups-control/create-backup-failure": "創建備份失敗",
    "editor/backups-control/backup-created": "備份已創建",
    "editor/backups-control/delete-backup-failure": "刪除備份失敗",
    "editor/backups-control/backup-deleted": "備份已刪除",
    "editor/backups-control/cloud-version": "雲端版本",
    "editor/backups-control/backup-name": "備份名稱",

    // * CollaboratorasControl
    "editor/collaborators-control/set-collaborator-role-failure":
      "設置協作者角色失敗",
    "editor/collaborators-control/add-collaborator-failure": "添加協作者失敗",
    "editor/collaborators-control/collaborator-removed": "協作者已刪除",
    "editor/collaborators-control/collaborator-added": "協作者已添加",
    "editor/collaborators-control/can-view": "可以瀏覽",
    "editor/collaborators-control/can-edit": "可以編輯",
    "editor/collaborators-control/can-administrate": "可以管理員管理",

    // * EditorToolBar
    "editor/toolbar/insert-image": "插入圖片",
    "editor/toolbar/upload-local-images": "上傳本地圖片",
    "editor/toolbar/capture-webpage": "截取整個網頁",
    "editor/toolbar/insert-clock": "插入時鐘",
    "editor/toolbar/insert-emoji": "插入 Emoji",
    "editor/toolbar/insert-media": "插入媒體",
    "editor/toolbar/insert-audio": "插入音頻",
    "editor/toolbar/netease-music": "網易雲音樂",
    "editor/toolbar/insert-video": "插入視頻",
    "editor/toolbar/insert-youtube": "插入 Youtube",
    "editor/toolbar/insert-bilibili": "插入 Bilibili",
    "editor/toolbar/insert-slide": "插入幻燈片",
    "editor/toolbar/insert-ocr": "插入 OCR",
    "editor/toolbar/insert-kanban": "插入看板",
    "editor/toolbar/insert-abc-notation": "插入 ABC notation",
    "editor/toolbar/insert-header-1": "插入一級標題",
    "editor/toolbar/insert-header-2": "插入二級標題",
    "editor/toolbar/insert-header-3": "插入三級標題",
    "editor/toolbar/insert-header-4": "插入四級標題",
    "editor/toolbar/insert-header-5": "插入五級標題",
    "editor/toolbar/insert-header-6": "插入六級標題",
    "editor/toolbar/bold": "粗體",
    "editor/toolbar/italic": "斜體",
    "editor/toolbar/strikethrough": "刪除線",
    "editor/toolbar/insert-inline-code": "插入行內代碼",
    "editor/toolbar/insert-blockquote": "插入引用",
    "editor/toolbar/insert-unordered-list": "插入無序列表",
    "editor/toolbar/insert-ordered-list": "插入有序列表",
    "editor/toolbar/insert-link": "插入鏈接",
    "editor/toolbar/insert-table": "插入表格",
    "editor/toolbar/insert-comment": "插入交叉筆記雲評論掛件",
    "editor/toolbar/insert-github-gist": "插入 GitHub Gist",

    // * NoteControl
    "editor/note-control/tag-added": "標籤已被添加",
    "editor/note-control/tag-deleted": "標籤已被刪除",
    "editor/note-control/set-note-visibility-failure": "設置筆記可見度失敗",
    "editor/note-control/note-visibility-updated": "筆記可見度已更新",
    "editor/note-control/set-author-visibility-failure": "設置作者可見度失敗",
    "editor/note-control/author-visibility-updated": "作者可見度已更新",
    "editor/note-control/editor-mode": "編輯器模式",
    "editor/note-control/source-code": "源代碼",
    "editor/note-control/preview": "預覽",
    "editor/note-control/split-view": "分欄",
    "editor/note-control/pin-preview": "固定預覽到右側",
    "editor/note-control/display-modification-records": "顯示修改記錄",
    "editor/note-control/note-visibility": "筆記可見度",
    "editor/note-control/author-visibility": "作者可見度",
    "editor/note-control/shareable-link": "可分享的鏈接",
    "editor/note-control/copy-to-clipboard": "複製到粘貼板",
    "editor/note-control/link-to-preview": "到預覽的鏈接",
    "editor/note-control/link-to-editor": "到編輯器的鏈接",
    "editor/note-control/attach-to-note": "附加到筆記",
    "editor/note-control/source-added": "鏈接已被添加",
    "editor/note-control/source-deleted": "鏈接已被刪除",
    "editor/note-control/invalid-note-source": "無效的鏈接",
    "editor/note-control/add-note-source": "添加相關的鏈接",
    "editor/note-control/note-source-hint":
      "所有相同鏈接的筆記將會被聚集到壹起",
    "editor/note-control/enter-note-source": "輸入鏈接",

    // AvatarPanel
    "avatar-panel/this-note-is-private": "這個筆記是私有的",
    "avatar-panel/this-note-is-public": "這個筆記是公開可見的",
    "avatar-panel/this-note-is-friends-only": "這個筆記僅對好友可見",
    "avatar-panel/you-are-anonymous": "你是匿名的",

    // FileExporter
    "file-exporter/name": "文件導出",
    "file-exporter/orientation": "方向",
    "file-exporter/portrait": "豎直",
    "file-exporter/landscape": "水平",
    "file-exporter/paper-size": "紙張大小",
    "file-exporter/unit": "單位",
    "file-exporter/margin-top": "上邊距",
    "file-exporter/margin-bottom": "下邊距",
    "file-exporter/margin-left": "左邊距",
    "file-exporter/margin-right": "右邊距",

    // Utils
    "utils/uploading-image": "正在上傳圖片...",
    "utils/upload-image-failure": "上傳圖片失敗",

    // Notification
    // * CommentPostingNotification
    "notification/replies-to-you-in": "回復了你在",
    "notification/'s-note": "的筆記 ",
    // * NoteReactionNotification
    "notification/added": " 添加了",
    "notification/to-your-note": "到你的筆記",
    "notification/add-you-as": "添加了你作為",
    "notification/of-note": "到筆記",
    // * NoteTagSuggestion
    "notification/add-tag-failure": "添加標籤失敗",
    "notification/suggested-to-add-tag": "建議添加標籤",
    // * UserFollowingNotification
    "notification/started-following-you": "開始關注了你",
    // * BookmarkNotification
    "notification/bookmarked-your-note": "收藏了你的筆記",
    "notification/view-more-notifications": "查看更多的消息",

    // GitHubOAuthCallback
    "github-oauth/wait": "請稍等 ...",
    "github-oauth/link-with-github-account-failure": "無法連接到 GitHub 賬號",
    "github-oauth/sign-in-with-github-account-failure":
      "使用 GitHub 賬號登錄失敗",
    "github-oauth/first-time-sign-in": "看上去這是你第一次使用 GitHub 賬號登錄",
    "github-oauth/first-time-sign-in-small": "請完成下方的註冊",

    // Widgets
    "widget/autoplay": "自動播放",
    "widget/controls": "控制器",
    "widget/loop": "循環",
    "widget/muted": "靜音",
    "widget/creating-widget": "正在創建掛件",
    "widget/loading-widget": "正在讀取掛件",
    "widget/crossnote.image/thanks_sm_ms":
      "在此由衷感謝 sm.ms 提供免費的圖床服務。",
    "widget/crossnote.audio/audio_element_fail":
      "你的瀏覽器不支持音頻 audio 元素",
    "widget/crossnote.audio/source-url-placeholder":
      "在這裡輸入音頻文件源鏈接，然後按 '回車' 鍵來插入",
    "widget/crossnote.video/video_element_fail":
      "你的瀏覽器不支持視頻 video 元素",
    "widget/crossnote.bilibili/error_message":
      "錯誤：僅支持 https://www.bilibili.com/video/av79126732 這樣的鏈接",
    "widget/crossnote.youtube/error_message":
      "錯誤：僅支持 https://www.youtube.com/watch?v=MmB9b5njVbA 這樣的鏈接",
    "widget/crossnote.auth/welcome": "歡迎使用交叉筆記",
    "widget/crossnote.auth/doesnt-have-account": "沒有帳號？咱們來創建一個😉！",
    "widget/crossnote.auth/forgot_password": "忘記你的密碼了 😣？",
    "widget/crossnote.auth/failed_to_send_code": "發送驗證碼到郵箱失敗",
    "widget/crossnote.auth/verification_code_sent": "驗證碼已經被發送到 ",
    "widget/crossnote.auth/failed_to_reset_password": "重置密碼失敗",
    "widget/crossnote.auth/password_reset": "你的密碼已被重置",
    "widget/crossnote.auth/password-mismatch": "密碼不一致",
    "widget/crossnote.auth/hello": "你好，",
    "widget/crossnote.auth/enter-the-verification-code": "輸入驗證碼",
    "widget/crossnote.auth/get-code": "獲取驗證碼",
    "widget/crossnote.auth/sign-in-with-github-account": "使用 GitHub 賬號登錄",
    "widget/crossnote.auth/already-have-an-account": "已有帳號？",
    "widget/crossnote.auth/reset-password": "重置密碼",
    "widget/crossnote.auth/Or": "或者",
    "widget/crossnote.bilibili/Bilibili": "Bilibili",
    "widget/crossnote.bilibili/bilibili-video-url": "Bilibili 視頻鏈接",
    "widget/crossnote.bilibili/url-placeholder":
      "在這裡插入 Bilibili 視頻的鏈接，然後按 '回車' 鍵來插入",
    "widget/crossnote.image/image-helper": "圖片助手",
    "widget/crossnote.image/image-url-placeholder":
      "在這裡輸入圖片鏈接，然後按 '回車' 鍵來插入",
    "widget/crossnote.image/click-here-to-browse-image-file":
      "點擊這裡來選擇圖片文件",
    "widget/crossnote.neteasemusic/song-id-placeholder":
      "輸入網易雲音樂歌曲數字 ID",
    "widget/crossnote.video/video-url-placeholder":
      "在這裡輸入視頻文件源鏈接，然後按 '回車' 鍵來插入",
    "widget/crossnote.video/poster-url": "封面圖片鏈接（可選填）",
    "widget/crossnote.video/poster-url-placeholder": "在這裡輸入封面圖片鏈接。",
    "widget/crossnote.youtube/youtube-video-url": "Youtube 視頻鏈接",
    "widget/crossnote.youtube/youtube-video-url-placeholder":
      "在這裡插入 Youtube 視頻的鏈接，然後按 '回車' 鍵來插入",
    "widget/crossnote.ocr/ocr": "OCR - 光學字元識別",
    "widget/crossnote.ocr/local-image": "本地圖片",
    "widget/crossnote.ocr/recognize-text-in-languages": "識別以下語言的文字",
    "widget/crossnote.ocr/start-ocr": "開始文字識別",
    "widget/crossnote.ocr/extra-settings": "額外設定",
    "widget/crossnote.ocr/grayscale": "灰度",
    "widget/crossnote.kanban/add-column": "添加新的列",
    "widget/crossnote.github_gist/title": "內嵌入 GitHub Gist",
    "widget/crossnote/github_gist/enter-github-gist-url":
      "輸入 GitHub Gist URL",

    // Tesseract
    "tesseract/loading tesseract core": "正在讀取 tesseract 核心",
    "tesseract/initializing tesseract": "正在初始化 tesseract",
    "tesseract/initialized tesseract": "已初始化 tesseract",
    "tesseract/loading language traineddata": "正在讀取語言訓練數據",
    "tesseract/loaded language traineddata": "已讀取語言訓練數據",
    "tesseract/initializing api": "正在初始化程序接口",
    "tesseract/initialized api": "已初始化程序接口",
    "tesseract/recognizing text": "正在識別文字",

    // Comment widget
    "widget/crossnote.comment/reply-to-this-user": "回復給這個用戶",
    "widget/crossnote.comment/add-reaction-to-this-comment":
      "給這條評論添加反應",
    "widget/crossnote.comment/edit-this-message": "編輯這條評論",
    "widget/crossnote.comment/modified-comment": "編輯过的評論",
    "widget/crossnote.comment/post-comment-failure": "發送評論失敗",
    "widget/crossnote.comment/unsubscribe-info": "取消訂閱評論",
    "widget/crossnote.comment/subscribe-info": "訂閱評論",
    "widget/crossnote.comment/view-more-comments": "查看更多的評論",

    // Dialogs
    "delete-note-dialog/title": "你確定要刪除這個筆記?",
    "delete-note-dialog/subtitle": "你無法撤銷此操作。",
    "delete-directory-dialog/title": "你確定要刪除這個目錄?",
    "delete-directory-dialog/subtitle":
      "所有子目錄以及筆記也將會被刪除。你無法撤銷此操作。",
    "delete-tag-dialog/title": "你確定要刪除這個標籤?",
    "delete-tag-dialog/subtitle": "所有子標籤也將會被刪除。你無法撤銷此操作。",
    "add-notebook-dialog/disclaimer":
      "所有筆記本的數據將只會被保存於瀏覽器本地以供離線使用。我們不會上傳任何你的筆記本數據到我們的伺服器。",
    "edit-image-dialog/title": "編輯圖片",
    "edit-image-dialog/image-url": "圖片 URL",
    "edit-image-dialog/image-title": "圖片標題",
    "edit-image-dialog/image-alt-text": "圖片替代文本",
    "language-selector-dialog/subtitle": "請選擇偏好語言",

    "react-ios-pwa-prompt/copy-title": "新增到主畫面",
    "react-ios-pwa-prompt/copy-body":
      "本網站具有應用功能。將其添加到主螢幕,以全屏和脫機時使用。",
    "react-ios-pwa-prompt/copy-share-button-label": "1) 按下「共用」按鈕",
    "react-ios-pwa-prompt/copy-add-home-button-label":
      "2) 按下「添加到主螢幕」",

    // Publish notebook
    "publish-notebook/disclaimer-1":
      "我們目前僅支援從 GitHub、GitLab、Gitea 或碼雲的公共倉庫發佈筆記本。 我們只收集 README.md 文件數據。",
    "publish-notebook/disclaimer-2":
      "請通過在遠端倉庫中向 README.md 添加以下 front-matter 來聲明筆記本的擁有權:",

    // git
    "git/status/ignored": "已忽略",
    "git/status/unmodified": "未變更",
    "git/status/*modified": "*已變更",
    "git/status/*deleted": "*已刪除",
    "git/status/*added": "*新增",
    "git/status/absent": "缺失",
    "git/status/modified": "已變更",
    "git/status/deleted": "已刪除",
    "git/status/added": "新增",
    "git/status/*unmodified": "*未變更",
    "git/status/*absent": "*缺失",
    "git/status/*undeleted": "*未刪除",

    // graph view
    "graph-view/node": "節點",
    "graph-view/link": "連接",
  },
};
